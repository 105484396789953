import Vue from 'vue'

/**
 * Уведомления из раздела "Сообщения"
 */
export default {
  state: {
    messages: [],
    offset: 0,
    limit: 10, 
    finished: false,
  },

  mutations: { 
    SET_MESSAGES(state, { messages, reset }) {
      if (reset) {
        state.messages = messages;
      } else {
        state.messages = state.messages.concat(messages);
      }
    },
    SET_OFFSET(state, offset) {
      state.offset = offset;
    },
    SET_FINISHED(state, finished) {
      state.finished = finished;
    },
  },

  actions: {
    fetchMessages({ commit, state }, { reset = false } = {}) {  
      const offset = reset ? 0 : state.offset;
      Vue.http.get(`api/user/notifications?limit=${state.limit}&offset=${offset}`)
        .then(response => {
          const messages = response.data; 
          commit('SET_MESSAGES', { messages, reset });
          commit('SET_OFFSET', offset + 1);

          if (messages.length < state.limit) {
             commit('SET_FINISHED', true);
          }

        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
